import React from 'react';
//import styles from './Footer.module.scss';
function Footer() {
    return (
        <footer className="bg-black p-2.5 text-center text-white text-opacity-70">
            <p>Content is under <a href='https://creativecommons.org/licenses/by-nc-sa/3.0/deed.fr' className='text-cyan-900 no-underline'>Creative Commons Attribution-Non-Commercial-ShareAlike 3.0 License</a> unless otherwise noted</p>
        </footer>
    );
}

export default Footer;
