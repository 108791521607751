import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import { DarkModeContextProvider } from './context/darkModeContext';
import { FactionProvider } from './context/FactionContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <FactionProvider>
      <App />
      </FactionProvider>
    </DarkModeContextProvider>
  </React.StrictMode>
);


